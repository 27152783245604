<template>
  <div class='wameed-dashboard-page-content'>
    <page-header
        :title="isUpdate?$t('talqah_business.providers.update_btn'):$t('talqah_business.providers.new_btn')"
        :paths="[
          {
            title:$t('nav.talqah_business.title'),
          },
          {
            title:$t('nav.talqah_business.providers.title'),
            link:'talqah-business-providers'
          },
        ]"
    />
    <section class='wameed-dashboard-page-content_body products_create'>
      <b-row class='px-2'>
        <b-col lg='12' md='12' class='px-0'
               :key="key">
          <b-card no-body class='wameed-card'>
            <b-card-header class="align-items-center tab-title">
              <h5 class='text-med-20 text-font-main mb-0'>
                {{ $t('talqah_business.providers.form.title') }}
              </h5>
              <div class="text-main text-md-20 cursor-pointer" v-if="isUpdate"
                   @click="resetPasswordModal=true">
                {{ $t('settings.system_users.reset_password') }}
              </div>
            </b-card-header>
            <wameed-form @onSubmit='submit'>
              <div slot-scope='{ invalid }'>
                <b-card-body
                    class='position-relative justify-content-between px-5'
                >
                  <!-- form -->
                  <div class='d-flex flex-wrap'>
                    <b-col cols='12' md='6'>
                      <text-input
                          id='name'
                          v-model='form.name'
                          :label="$t('talqah_business.providers.form.provider_name')"
                          rules='required'
                          :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>

                    <b-col cols='12' md='6'>
                      <TextInput
                        v-model='form.username'
                        field-classes='w-100'
                        rules='required'
                        :label="'اسم المستخدم'"
                        :placeholder="$t('form.text.placeholder')"
                        type='text'
                      />
                    </b-col>

                    <b-col cols='12' md='6'>
                      <TextInput
                          v-model='form.email'
                          field-classes='w-100'
                          rules='required|email'
                          :label="$t('form.email.label')"
                          :placeholder="$t('form.text.placeholder')"
                          type='text'
                      />
                    </b-col>

                    <b-col cols='12' md='6'>
                      <TextInput
                        v-model='form.password'
                        field-classes='w-100'
                        rules='required'
                        :label="'كلمة المرور'"
                        :placeholder="$t('form.text.placeholder')"
                        type='number'
                      />
                    </b-col>


<!--                    <b-col cols='12' md='4' class="z-100">
                      <wameed-image-cropper-input
                          :label="$t('users.vendor.form.profile_bg')"
                          :placeholder="$t('form.image.placeholder')"
                          :uploaded-image="form.temp_profile_bg"
                          :get-object="true"
                          :fixed="true"
                          @changed="uploadBackgroundImage"
                          :width="323"
                          :height="180"
                      />
                      <wameed-input-file-upload
                          fileType='images'
                          srcName='url'
                          field-classes='w-100 z-100'
                          functionType='front'
                          :value="form.temp_profile_bg&&form.temp_profile_bg.length>0?form.temp_profile_bg[0].url:''"
                          :uploadedFiles='form.temp_profile_bg'

                          :input-placeholder=""
                          :input-append-text="$t('form.image.browse')"
                          @changed='uploadBackgroundImage'
                          @deleteImage='deleteBackgroundImage'
                      />
                    </b-col>-->

                    <b-col cols='12' md='6'>
                      <wameed-dropdown
                        :no_options_search="$t('common.no_options_search')"
                        v-model='form.vehicleType'
                        :label="$t('talqah_business.providers.form.vehicle_type')"
                        :placeholder="$t('talqah_business.providers.form.vehicle_type')"
                        variant='disable'
                        :items="vehicleTypes"
                        class="wameed_dropdown"
                        title="name"
                        rules='required'
                        :no_options="$t('common.no_options')"
                      />
                    </b-col>

                    <b-col cols='12' md='12'>
                      <h5>{{ $t('talqah_business.providers.form.pricing_type') }}</h5>
                      <div class="d-flex flex-wrap mb-5" style="gap:16px">
                        <button type="button" class="discount_on_btn"
                                v-for="pricingType in pricingTypes"
                                :key="pricingType.value"
                                :class="[pricingType.value === form.pricing_type_value?'active':'']"
                                @click="()=>changePricingType(pricingType)">
                          {{ pricingType.name }}
                        </button>
                      </div>
                    </b-col>

                    <div class="d-flex flex-wrap mb-5 w-100" v-if="form.pricing_type_value === 'distance_based'">
                      <b-col cols='12' md='4'>
                        <TextInput
                          :readonly="isUpdate"
                          v-model="form.initial_distance"
                          rules="required"
                          field-classes="w-100"
                          :label="$t('talqah_business.providers.form.initial_distance')"
                          :placeholder="$t('form.text.placeholder')"
                          type="number"
                        />
                      </b-col>
                      <b-col cols='12' md='4'>
                        <TextInput
                          :readonly="isUpdate"
                          v-model="form.initial_price"
                          rules="required"
                          field-classes="w-100"
                          :label="$t('talqah_business.providers.form.initial_price')"
                          :placeholder="$t('form.text.placeholder')"
                          type="number"
                        />
                      </b-col>
                      <b-col cols='12' md='4'>
                        <TextInput
                          :readonly="isUpdate"
                          v-model="form.per_500m_price"
                          rules="required"
                          field-classes="w-100"
                          :label="$t('talqah_business.providers.form.per_500m_price')"
                          :placeholder="$t('form.text.placeholder')"
                          type="number"
                        />
                      </b-col>
                    </div>

                    <div class="d-flex flex-wrap mb-5 w-100" v-if="form.pricing_type_value === 'fixed_per_500m'">
                      <b-col cols='12' md='4'>
                        <TextInput
                          :readonly="isUpdate"
                          v-model="form.initial_price"
                          rules="required"
                          field-classes="w-100"
                          :label="$t('talqah_business.providers.form.initial_price')"
                          :placeholder="$t('form.text.placeholder')"
                          type="number"
                        />
                      </b-col>
                    </div>
                  </div>


                </b-card-body>
                <b-card-footer class='px-4 py-3'>
                  <div class='d-flex flex-wrap justify-content-between'>
                    <div class='px-4 py-3'>
                      <wameed-btn
                          classes='  text-med-14 text-white rounded-10'
                          :title="isUpdate?$t('talqah_business.providers.update_btn'):$t('talqah_business.providers.new_btn')"
                          :disabled='invalid'
                          type='submit'
                          variant='main'
                      />
                    </div>

                    <div class='px-4 py-3'>
                      <wameed-btn
                          classes='  text-med-14 text-font-secondary rounded-10 '
                          :title="$t('common.cancel')"
                          type='button'
                          variant='gray'
                          @onClick='goBack'
                      />
                    </div>
                  </div>
                </b-card-footer>
              </div>

<!--              <b-card no-body class="wameed-card mb-5">
                <b-card-header class="align-items-start tab-title">
                  <h5 class="text-med-20 text-font-main mb-0">
                    {{ $t('talqah_business.providers.form.on') }}
                  </h5>
                </b-card-header>
                <b-card-body
                  class="position-relative justify-content-between  mt-5 px-5"
                >
                  &lt;!&ndash; form &ndash;&gt;
                  <div class="d-flex flex-wrap mb-5" style="gap:16px">

                    <button type="button" class="discount_on_btn"
                            v-for="on in vehiclesList"
                            :key="on.id"
                            :class="[on.id === form.discount_on_id?'active':'', isUpdate?'disable':'',isProductDiscountAndCanChange?'disable':'',]"
                            @click="()=>changeVehicleType(on)">
                      {{ on.name }}
                    </button>
                  </div>

                </b-card-body>
              </b-card>-->
            </wameed-form>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <map-modal
        @setLocation='setLocation'
        :visible='showMapModal'
        @close='showMapModal = false'
    />


    <warning-modal
        variant='main'
        iconName='o-sent-icon'
        :visible='showSentModal'
        @close='goBack'
        @submitAction='goBack'
        :title="$t('users.vendor.sent.title')"
        :subTitle="$t('users.vendor.sent.subTitle')"
        :btnTitle="$t('common.OK')"
    />

    <warning-modal
        variant='warning'
        iconName='o-warning-icon'
        :visible="resetPasswordModal"
        @close="resetPasswordModal = false"
        @submitAction="resetPasswordAction"
        :title="$t('settings.system_users.rest_modal.title')"
        :subTitle="$t('settings.system_users.rest_modal.desc')"
        :btnTitle="$t('btn.ok')"
    />
  </div>
</template>

<script>

import {mapGetters} from 'vuex';

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import {
  WameedBtn,
  WameedDropdown,
  WameedForm,
  WameedTextArea as TextArea,
  WameedTextInput as TextInput
} from 'wameed-ui/dist/wameed-ui.esm';
import MapModal from '@/components/mapModal';
import WarningModal from '@/components/WarningModal';
import WameedImageCropperInput from "@/components/wameed/WameedImageCropperInput.vue";
import { PaymentType } from '@/enums/paymentType.enum';
import CreateDiscountType from '@/views/pages/discounts/sections/createDiscountType.vue';
import CreateDiscountCondition from '@/views/pages/discounts/sections/createDiscountCondition.vue';

export default {
  components: {
    CreateDiscountCondition, CreateDiscountType,
    WameedImageCropperInput,
    WarningModal,
    MapModal,
    TextInput,
    TextArea,
    WameedBtn,
    WameedDropdown,
    WameedForm,
    PageHeader
  },
  data() {
    return {
      key: 1,
      showMapModal: false,
      showSentModal: false,
      vehicleTypes: [],
      pricingTypes: [
        {
          value: "distance_based",
          name: "بحسب المسافة",
        },
        {
          value: "fixed_per_500m",
          name: "سعر ثابت / 500 متر",
        }
      ],
      form: {
        name: '',
        username: '',
        email: '',
        password: '',
        vehicleType: null,
        pricing_type_value: null,
        initial_distance: null, // For distance-based pricing
        initial_price: null, // Price for the initial distance
        per_500m_price: null, // Price per 500 meters after initial distance
      }

    };
  },
  computed: {
    ...mapGetters({
      getVehicles: 'admin/talqahBusiness/vehicles/getData',
      getVendorClassification: 'admin/users/getVendorClassification',
      userDetail: 'admin/users/getDetail'
    }),


    showOrderTime() {
      return this.form.category.id !== 3;
    },

    maxOrderTime() {
      let value = this.form.min_order_time;
      if (value) {
        return '|min_value:' + value
      }
      return '';
    },

    tempProfileImageValue() {
      if (this.form.temp_profile_image.length > 0)
        return this.form.temp_profile_image[0].url
      return '';
    },
    tempBgImageValue() {
      if (this.form.temp_profile_bg && this.form.temp_profile_bg.length > 0)
        return this.form.temp_profile_bg[0].url
      return '';
    },
    isUpdate() {
      return (this.$route.name === 'users-vendors-update' && this.$route.params.id);
    }
  },
  async beforeCreate() {


    if (this.$route.name === 'users-vendors-update' && this.$route.params.id) {
      this.$store.dispatch('admin/users/simpleDetail', {id: this.$route.params.id, type: 'vendors'}).then(async () => {

        await this.$store.dispatch('admin/users/getVendorClassification', this.userDetail.category_id);

        this.form = this.userDetail;
        if (this.userDetail.profile_image)
          this.form.temp_profile_image = {url: this.userDetail.profile_image,name: this.userDetail.profile_image};
        if (this.userDetail.profile_bg)
          this.form.temp_profile_bg = {url: this.userDetail.profile_bg,name:this.userDetail.profile_bg};

        this.form.category = this.getVendorCategories.find((cat) => cat.id === this.userDetail.category_id);
        this.form.classifications = this.getVendorClassification.filter((cat) => this.userDetail.classifications_ids.includes(cat.id));
      });

    } else {
      await this.$store.dispatch('admin/users/unsetDetail');
    }
  },


  methods: {
    changePricingType(pricingType) {
      this.form.pricing_type_value = pricingType.value;
    },
    uploadProfileImage(value) {
      this.key++;
      this.form.temp_profile_image = value;
    },
    deleteProfileImage() {
      this.key++;
      this.form.temp_profile_image = [];
    },
    uploadBackgroundImage(value) {
      this.key++;
      this.form.temp_profile_bg = value;
    },
    deleteBackgroundImage() {
      this.key++;
      this.form.temp_profile_bg = [];
    },
    setLocation(value) {
      // this.form.address = value.address;
      this.form.geo_location = value.location;
      this.showMapModal = false;
    },
    goBack() {
      this.$router.push({name: 'users-vendors'});
    },

    async onCategoryChange() {
      this.form.classifications = [];
      await this.$store.dispatch('admin/users/getVendorClassification', this.form.category.id);

    },
    resetPasswordAction() {
      this.$store.dispatch('admin/users/resetVendorPassword', this.$route.params.id).then((item) => {
        if (item === 1) {
          setTimeout(() => {
            this.showSentModal = true
          }, 500)
        }
      }).finally(() => {
        this.resetPasswordModal = false;
      });
    },
    submit() {

      if (this.$route.name === 'talqah-business-providers-update' && this.$route.params.id) {
        this.form.id = this.$route.params.id;
        this.$store.dispatch('admin/talqahBusiness/providers/update', this.form);
      } else {
        let data = {
          name: this.form.name,
          username: this.form.username,
          email: this.form.email,
          password: this.form.password,
          vehicle_id: this.form.vehicleType.id,
          pricing_type: this.form.pricing_type_value,
          initial_distance: this.form.initial_distance,
          initial_price: this.form.initial_price,
          per_500m_price: this.form.per_500m_price,
        };
        this.$store.dispatch('admin/talqahBusiness/providers/create', data).then(() => {
          this.showSentModal = true;
        });
      }

    }
  },
  async mounted() {
    await this.$store.dispatch('admin/talqahBusiness/vehicles/get');
    this.vehicleTypes = this.getVehicles.map((vehicle) => {
      return { id: vehicle.id, name: vehicle.name_ar }
    });
  }

}
;
</script>

<style scoped>
.imageHolder {
  z-index: 99;
}
</style>
